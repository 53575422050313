<template>
  <div class="background">
    <div class="logo">
      <img src="../../assets/logo_blanc.png">
    </div>
    <div class="login">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: "ConnectionView",
  components : {}
}
</script>

<style lang="scss" scoped>

  @media (min-width: 1000px) {
    .background{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color:RGBA(121,7,72,1);

      .logo{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 45%;
          height: 15%;
        }
      }
      .login{
        overflow: auto !important;
        width: 60%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 40%;
        right: 0;
        background-color: #ffffff;
        border-bottom-left-radius: 10%;
        border-top-left-radius: 10%;
        -webkit-border-bottom-left-radius: 10%;
        -webkit-border-top-left-radius: 10%;
      }
    }
  }

  @media (max-width: 1000px) {
    .background{
      height: 100%;
    }
    .logo{
      display: none !important;
    }
    .login{
      width: 60% !important;
      height: 100%;
      margin: 0 auto;
      overflow: auto !important;
    }
  }

</style>
